<template>
  <div class="w-full">
    <div class="grid grid-cols-1">
      <div class="grid lg:grid-cols-2 grid-cols-1 items-center lg:w-[85%] w-full mx-auto">
        <div
          class="lg:text-left text-center text-neutral-900 xl:text-[64px] text-[40px] font-medium font-['Montserrat'] capitalize xl:leading-[85px] leading-[50px] mb-5"
        >
          Why Choose Canopy Roofing?
        </div>
        <div class="lg:flex lg:justify-end justify-center h-[60px] mb-5 hidden">
          <CommonButtonsEstimate text="Free Roofing Estimate" :icon="true" />
        </div>
      </div>
      <div class="wrapper-with-services" v-lazy-background data-bg="/services/bg.webp">
        <div class="xl:w-[85%] w-[95%] mx-auto">
          <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-10">
            <!-- Your items here -->
            <div
              v-for="item in whyCanopyRoofings"
              :key="item.title"
              class="bg-white rounded-[25.72px] flex flex-col items-center justify-center mt-10 hover:bg-orange-600 cursor-default group lg:p-10 p-5"
            >
              <div class="flex flex-col w-full lg:items-start items-center">
                <div
                  class="flex items-center justify-center text-center border border-[#e2e2e2] rounded-full h-[100px] w-[100px] min-h-[100px] min-w-[100px] group-hover:bg-orange-600"
                >
                  <img v-lazy-load  :data-src="item.icon" class="svg-icon">
                </div>
              </div>
              <div class="flex flex-col w-full h-full mt-5 lg:text-left text-center">
                <div
                  class="text-neutral-900 lg:text-3xl text-2xl font-medium font-['Montserrat'] leading-[30px] w-full group-hover:text-white"
                >
                  {{ item.title }}
                </div>
                <div
                  class="text-neutral-900 lg:text-xl text-lg font-medium font-['Montserrat'] leading-[30px] w-full mt-5 group-hover:text-white"
                >
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex lg:justify-end justify-center h-[60px] mb-10 mt-10 lg:hidden">
            <CommonButtonsEstimate text="Free Roofing Estimate" :icon="true" />
          </div>
          <CommonService class="lg:mt-24 mt-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

const whyCanopyRoofings = [
  {
    title: 'Veteran Operated Company',
    description: 'At Canopy Roofing, we are a fully licensed and insured roofing contractor that is Veteran operated. We take pride in our commitment to efficiency, quality, and safety with every service we offer. With decades of experience in the industry, we are the go-to roofing contractor in Georgia.',
    icon: '/why-canopy/veteran-operated.svg'
  },
  {
    title: 'Industry Leading Warranties',
    description: 'We provide warranty coverage on any work we perform. If your problem is covered by a manufacturer\'s warranty, we work with the manufacturer and make sure that all work is performed within their product specifications.',
    icon: '/why-canopy/industry-leading-warranties.svg'
  },
  {
    title: 'Expert Technicians',
    description: 'Canopy uses the rigorous training and quality control to ensure our technicians are the best in the industry. Every technician is fully certified. Every project has the highest standard of workmanship and professionalism.',
    icon: '/why-canopy/expert-technicians.svg'
  }
]
</script>

<style scoped>
.wrapper-with-services {
  background-position: 0 100px;
  background-repeat: no-repeat;
  background-size: cover;
}

.group:hover .svg-icon {
  filter: brightness(0) invert(1);
}
</style>
